import React from "react";

// mui components
import { TableCell, TableRow } from "@mui/material";

// custom components
import Body3 from "../../typography/body-03";
import Body2 from "../../typography/body-02";

// custom styles
import styles from "../index.module.scss";

const EligibleProductAdjustmentsRow = ({ id, row, rowData, extraData }) => {
	console.log("15 extra data:", id, extraData);
	console.log("15 row data:", rowData);

	return (
		<TableRow hover tabIndex={-1} className={styles.rowContainer}>
			<TableCell>
				{id === 0 || id === rowData?.length - 1 ? (
					<Body2
						text={row?.reason}
						extraClass={styles.greenText}
						fontType="semibold"
					/>
				) : (
					<Body3 text={row?.reason} extraClass={styles.greenText} />
				)}
			</TableCell>
			<TableCell align="right">
				{/* first (base) and last (total) item in array */}
				{id === 0 || id === rowData?.length - 1 ? (
					<Body2
						text={`${Number(row?.rate)?.toFixed(3)} %`}
						extraClass={styles.greenText}
						fontType="semibold"
					/>
				) : (
					<Body3
						text={
							row?.type?.toLowerCase()?.includes("rate")
								? `${Number(row?.adjustor)?.toFixed(3)} %`
								: "0.000%"
						}
						extraClass={styles.greenText}
					/>
				)}
			</TableCell>
			<TableCell align="right">
				{/* first (base) and last (total) item in array */}
				{id === 0 || id === rowData?.length - 1 ? (
					<Body2
						text={`${Number(row?.adjustor)?.toFixed(3)} %`}
						extraClass={styles.greenText}
					/>
				) : (
					<Body3
						text={
							row?.type?.toLowerCase()?.includes("price")
								? `${Number(row?.adjustor)?.toFixed(3)} %`
								: "0.000%"
						}
						extraClass={styles.greenText}
					/>
				)}
			</TableCell>
		</TableRow>
	);
};

export default EligibleProductAdjustmentsRow;
